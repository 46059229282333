import React from 'react';

import StockistStyles from './stockist.module.scss';

declare global {
    interface Window {
        stockistRebuildWidget: any;
    }
}

const Stockist = ({ type = 'cycle' }: { type?: 'cycle' | 'mirror' | 'standalone' }) => {
    const [headlineColor, setHeadlineColor] = React.useState('color-purple');

    if (typeof window !== 'undefined') {
        const stockistScript = require('./stockist-script');
    }

    React.useEffect(() => {
        if (type === 'cycle') {
            setHeadlineColor('color-green');
        } else if (type === 'mirror') {
            setHeadlineColor('color-pink');
        } else if (type === 'standalone') {
            setHeadlineColor('color-purple');
        }

        if (typeof window !== 'undefined' && window.stockistRebuildWidget) {
            window.stockistRebuildWidget();
        }
    }, []);

    return (
        <div className={StockistStyles.stockist}>
            <div className={StockistStyles.header}>
                <h3 className={headlineColor}>STORES IN DEINER NÄHE</h3>
                <p className="color-black">
                    Lass dich in einem Store beraten. In ausgewählten John Reed- und McFit-Studios kannst du das @Cycle
                    und den @Mirror auch direkt live erleben.
                </p>
            </div>

            <div data-stockist-widget-tag="u7915" className={StockistStyles.embedContainer}>
                <p>Händlersuche wird geladen...</p>
            </div>
        </div>
    );
};

export default Stockist;
