import { useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import PageLayout, { PageLayoutBackground } from '../components/common/page-layout/page-layout';
import Stockist from '../components/content/stockist/stockist';
import SectionIntended from '../components/templates/section-intended/section-intended';

const StoresPage = () => {
    const intl = useIntl();

    return (
        <PageLayout title={intl.formatMessage({ id: 'pages.stores.title' })} pageBackground={PageLayoutBackground.Grey}>
            <SectionIntended>
                <div style={{ marginBottom: '80px' }}>
                    <Stockist type="standalone" />
                </div>
            </SectionIntended>
        </PageLayout>
    );
};

export default StoresPage;
