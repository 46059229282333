import React from 'react';
import { Color } from '../../../_shared/enums/color';

import SectionIntendedStyles from './section-intended.module.scss';

interface SectionIntendedProperties {
    children: any;
    className?: string;
    noPadding?: boolean;
}

const SectionIntended = ({ children, className = '', noPadding = false }: SectionIntendedProperties) => {
    return (
        <div
            className={[
                SectionIntendedStyles.sectionIntended,
                className,
                noPadding ? SectionIntendedStyles.noPadding : '',
            ].join(' ')}
        >
            {children}
        </div>
    );
};

export default SectionIntended;
